<template>
  <div>
    <div v-for="point in tnList" :key="`point${point.point}`">
      <ItemCard v-for="tn in point.documents" :key="tn.tn" :is-edit="editedTnNumber === tn.tn">
        <v-layout wrap align-center fill-height>
          <v-flex xs12 sm2 md1>{{ tn.tn }}</v-flex>
          <v-flex xs12 sm10 md11>
            {{ tn.source_point.text }} - {{ tn.destination_point.text }}
          </v-flex>
        </v-layout>
        <template slot="buttons">
          <custom-button
            flat
            color="primary"
            class="my-0"
            @click="$emit('download', { numberPoint: point.point, tn })"
          >
            <icon name="pdf" size="18" />
          </custom-button>

          <custom-button
            v-if="isShipper"
            :disabled="isOrderEnded"
            flat
            color="primary"
            class="my-0"
            @click="$emit('change-tn-number', tn.tn)"
          >
            <icon name="pencil" size="20" />
          </custom-button>

          <custom-button
            v-if="isShipper"
            :disabled="isOrderEnded"
            :loading="deleteElement === tn.tn"
            flat
            color="primary"
            class="my-0"
            @click="$emit('delete', point.point, tn.tn)"
          >
            <icon name="deleteIcon" size="20" />
          </custom-button>
        </template>

        <template #content>
          <WayBillForm
            :is-shipper-fl="isShipperFl"
            :tn-point="point.point"
            :info-tn="tn"
            @save-tn="$emit('save')"
            @close="$emit('change-tn-number', null)"
          />
        </template>
      </ItemCard>
    </div>
  </div>
</template>

<script>
const ItemCard = () => import('common/components/Orders/ItemCard');
const WayBillForm = () => import('common/views/OrderView/OrderView/WayBillForm');

export default {
  name: 'ShippingInvoicesList',
  components: {
    ItemCard,
    WayBillForm
  },
  props: {
    isShipper: Boolean,
    isOrderEnded: Boolean,
    isShipperFl: Boolean,
    tnList: {
      type: Array,
      default: () => []
    },
    editedTnNumber: [Number, null],
    deleteElement: [Number, null]
  }
};
</script>
